import React, { Suspense, lazy } from 'react'
import { BlogDetails } from '@ashleynexvelsolutions/blog-details'
import { Seo2 } from '@ashleynexvelsolutions/seo2'
import { graphql } from 'gatsby'
import { Layout } from '../components/Layout'
import { SeoFunction, PageSpecificSchemaFunction, BlogDetailsFunction } from '../DataImports'

const PageSpecificSchema = lazy(() =>
  import('@ashleynexvelsolutions/page-specific-schema').then(module => ({
    default: module.PageSpecificSchema,
  })),
)

const BlogPost = ({ data }) => {
  return (
    <Layout>
      <div className="blogPost bg-white">
        <BlogDetails {...BlogDetailsFunction(data)} />
      </div>
      <Suspense fallback="loading">
        <PageSpecificSchema {...PageSpecificSchemaFunction(data)} />
      </Suspense>
    </Layout>
  )
}
export const query = graphql`
  query individualBlogPostQuery($id: String!) {
    wpPost(id: { eq: $id }) {
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphUrl
        opengraphSiteName
        opengraphType
        opengraphImage {
          localFile {
            publicURL
          }
        }
        canonical
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            publicURL
          }
        }
      }
      nexvelschemapagesposts {
        videos {
          title
          url
          description
          thumbnailImage {
            localFile {
              publicURL
            }
          }
          uploadDate
        }
        questionsAndAnswers {
          question
          answer
        }
        maps {
          mapUrl
        }
        digitaldocuments {
          title
        }
        images {
          image {
            localFile {
              publicURL
            }
            date(formatString: "LL")
            description
            title
          }
        }
      }
      author {
        node {
          firstName
          lastName
        }
      }
      title
      content
      uri
      date(formatString: "LL")
      modified(formatString: "LL")
      categories {
        nodes {
          name
        }
      }
      tags {
        nodes {
          name
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
      featuredImageAltText {
        featuredImageAltText
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    wp {
      acfOptionsCommonItems {
        commonItems {
          commonItemsPostHeroImageDefault {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          commonItemsPostHeroImageDefaultAltText
        }
      }
    }
  }
`
export default BlogPost
export const Head = ({ data }) => <Seo2 {...SeoFunction(data)} />
